<template>
  <div :class="disabled && 'cursor-not-allowed'">
    <label
      v-if="title && !hideTitle"
      class="text-gray-700 font-inter text-sm font-medium mb-1.5 flex"
    >
      {{ $t(`placeholder.${title}`) }}
      <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
    </label>
    <!-- CONTAINER STARTED -->
    <div class="relative">
      <div
        :class="[isErrorAvailable && 'bg-bg-error border-text-color-danger', customClasses]"
        class="flex rounded-lg flex-row pl-3.5 gap-4 border"
      >
        <!-- COUNTRY SELECT -->
        <div class="flex items-center gap-1.5 cursor-pointer" @click="toogleCountrySelectModel">
          <span class="text-base font-normal">
            {{ selectedCountry }}
          </span>
          <div class="">
            <Icon
              class="icon"
              icon="chevronBottom"
              color="primary-grey-light"
              height="5"
              width="10"
            />
          </div>
        </div>
        <!-- NUMBER INPUT -->
        <div class="flex items-center w-full text-base font-normal">
          <span class="mr-1">{{ selectedCountryCode }}</span>

          <Field
            v-model="activePhoneNumber"
            :class="isErrorAvailable && 'error-bg bg-bg-error'"
            class="focus:outline-none w-full"
            :placeholder="placeHolder"
            :name="name"
            type="text"
            autocomplete="off"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :maxlength="15"
            :disabled="disabled"
            :rules="parsedRules"
            @input="updatePhoneNumber"
            @keydown.enter.prevent
          />
        </div>
      </div>
      <!-- COUNTRY DROPDOWN -->
      <OnClickOutside :do="closeCountrySelectModel">
        <div
          v-show="countrySelectModel"
          class="absolute shadow-xl px-2 z-10 bg-white border rounded-lg mt-2 max-h-80 overflow-auto py-2.5 w-full"
        >
          <!-- SEARCH -->
          <div class="flex border items-center gap-2 px-3.5 h-11 rounded-lg">
            <div>
              <Icon class="icon" icon="search" color="stroke-gray-500" height="15" width="15" />
            </div>
            <div class="search-input w-full">
              <input
                v-model="searchedValue"
                :disabled="disabled"
                class="focus:outline-none w-full text-base"
                placeholder="Search ..."
                @keydown.enter.prevent
              />
            </div>
          </div>
          <!-- HR-LINE -->
          <Divider />
          <!-- COUNTRY LIST -->
          <div class="px-3.5">
            <div
              v-for="(country, index) in filteredCountries"
              :key="index"
              class="flex flex-row w-full py-2.5 hover:bg-gray-50 cursor-pointer text-base"
              @click="
                updateSelectedValue({
                  name: country[0],
                  codeAbbreviation: country[1],
                  countryCode: '+' + country[2],
                })
              "
            >
              <span>
                {{ country[0] }}
                <span class="text-gray-500">+{{ country[2] }}</span>
              </span>
            </div>
            <div v-if="!filteredCountries.length">
              <NoRecordFound />
            </div>
          </div>
        </div>
      </OnClickOutside>
    </div>
    <div ref="errorContainer" class="flex justify-end h-5">
      <ErrorMessage v-slot="{ message }" :name="name">
        <small class="error-msg text-text-color-danger text-sm italic">
          <p>{{ displayError(message) }}</p>
        </small>
      </ErrorMessage>
    </div>
  </div>
</template>

<script>
/* COMPONENTS */
import { Field, ErrorMessage, configure, defineRule } from 'vee-validate'
import Icon from '@components/icons/icon.vue'
import OnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
/* CONSTANTS countries */
import countries from '@src/constants/countriesArray'
/* 3rd Party components */
import { buildWhereQuery } from '@src/utils/filters/index.js'

import validationMixin from '@src/mixins/components/validation-mixin.js'
import parsePhoneNumber from 'libphonenumber-js'
import phoneNumberExamples from 'libphonenumber-js/mobile/examples'
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
import { validationConfiguration } from '@src/vee-validate/index.js'
import Divider from '@src/components/Divider.vue'
configure(validationConfiguration)
/**
 * @IMPORTANT_NOTES
 * ERROR MASSAGE HANDLED ON CREATED
 * ON INPUT
 * ON COUNTRY CHANGED
 *  */

export default {
  name: 'UiPhoneNumber',
  components: {
    Field,
    ErrorMessage,
    Icon,
    NoRecordFound,
    OnClickOutside,
    Divider,
  },
  mixins: [validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: '',
    },
    rules: { type: String, default: '' },
    name: { type: String, default: 'Phone Number' },
    title: { type: String, default: '' },
    customClasses: { type: String, default: 'h-11' },
    isVerified: {
      type: [Boolean, String],
      default: '',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'update', 'emitQuery'],
  data() {
    return {
      searchedValue: '',
      filteredCountries: [],
      selectedCountry: '',
      isErrorAvailable: false,
      selectedCountryCode: '',
      activePhoneNumber: '',
      placeHolder: '(101)',
      countrySelectModel: false,
      allCountries: countries,
    }
  },
  computed: {
    parsedRules() {
      if (!this.rules) return
      let rulesList = this.rules.split('|')
      rulesList = rulesList.map((val) => {
        if (val === 'validNumber') {
          val = `validNumber:${this.selectedCountry.toUpperCase()}`
        }
        return val
      })
      return rulesList.join('|')
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.errorHandler()
      },
    },
    rules: {
      handler() {
        this.errorHandler()
      },
    },
    selectedCountry: {
      handler(val) {
        if (val) this.placeHolder = phoneNumberExamples[val.toUpperCase()]
      },
      immediate: true,
    },
    /**
     * @description SEARCH IS IPLEMENTED HERE
     */
    searchedValue: {
      handler: function (searchString) {
        var filteredCountries = []
        searchString = searchString.toLowerCase()
        var withoutPlusSign = searchString.replace('+', '')
        this.allCountries.forEach((val, ind) => {
          if (
            val[0].toLowerCase().includes(searchString) ||
            val[1].toLowerCase().includes(searchString) ||
            val[2].toLowerCase().includes(withoutPlusSign)
          ) {
            filteredCountries.push(val)
          }
        })
        this.filteredCountries = filteredCountries
        if (searchString === '' || searchString === ' ') this.filteredCountries = this.allCountries
      },
      immediate: true,
    },
  },
  /**
   * Before mount logic for Edit Number
   *  */
  created() {
    /* SETTING DEFAULT COUNTRIES CODE & HANDLING EDIT*/
    this.selectedCountry = 'GB'
    this.selectedCountryCode = '+44'
    if (this.modelValue) {
      this.assignValues(this.modelValue)
    }
  },

  methods: {
    assignValues(val) {
      /* FOR HALF NUMBER EG. +92301 */
      this.activePhoneNumber = this.addPlusPrefixToPhone(val)
      const parsedObj = parsePhoneNumber(this.activePhoneNumber)
      if (parsedObj && parsedObj.country) {
        /* FOR FULL NUMBER EG. +923014408635 */
        this.activePhoneNumber = parsedObj.nationalNumber
        this.selectedCountry = parsedObj?.country
        this.selectedCountryCode = `+${parsedObj.countryCallingCode}`
        this.$emit('update:modelValue', parsedObj.number)
      }
    },
    addPlusPrefixToPhone(number) {
      // Trim any leading or trailing whitespace
      number = number.trim()

      // Check if the number starts with a '+'
      if (!number.startsWith('+')) {
        number = '+' + number
      }

      return number
    },
    updatePhoneNumber($event) {
      /**
       * @HANDLED_EDGE_CASES
       * 1. ADDING + AT START
       * 2. ADDING + ONLY 1 TIME AT START
       * 3. ADDED VALUE IS A NUMBER ONLY
       * 4. ADDED VALUE IS A ZERO
       * 5. CHECK IF INPUT DATA IS NUMBER ONLY
       */
      let isValidData = false
      let query
      if (
        $event.data === '+' &&
        $event.target.selectionStart === 1 &&
        ($event.target.value.length === 1 || !$event.target.value.substring(1).includes('+'))
      ) {
        isValidData = true
      }
      /**
       * NULL is used for backspace or removing number
       **/
      if (+$event.data || +$event.data === 0 || $event.data === null) {
        isValidData = true
      }
      /* HANDLING SPACE OR DATA SHOULD NOT BE EQUAL TO HERE*/
      if ($event.data === ' ') {
        isValidData = false
      }
      if (isValidData) {
        const parsedObj = parsePhoneNumber($event.target.value, this.selectedCountry)
        let returnVal = $event.target.value
        if (parsedObj) {
          returnVal = parsedObj.number
        }
        this.activePhoneNumber = $event.target.value
        this.$emit('update:modelValue', returnVal)
        this.$emit('update', { formattedNumber: returnVal })
        if (this.filter && this.filter.option)
          query = buildWhereQuery(this.filter.option, this.filter.key, returnVal)
        this.$emit('emitQuery', query)
      }
    },
    /**
     * @description For toggling country list model
     */
    toogleCountrySelectModel() {
      this.countrySelectModel = !this.countrySelectModel
    },
    /**
     * @description For toggling country list model
     */
    closeCountrySelectModel() {
      this.countrySelectModel = false
      this.searchedValue = ''
    },
    /**
     * @description used to select new country.
     */
    updateSelectedValue(selectedCountryObject) {
      const parsedObj = parsePhoneNumber(
        this.activePhoneNumber,
        selectedCountryObject.codeAbbreviation.toUpperCase(),
      )
      if (parsedObj) {
        if (this.selectedCountry !== selectedCountryObject.codeAbbreviation.toUpperCase()) {
          parsedObj.number = ''
          this.activePhoneNumber = ''
        }
        this.$emit('update:modelValue', parsedObj.number)
        this.$emit('update', { formattedNumber: parsedObj.number })
      }
      this.selectedCountry = selectedCountryObject.codeAbbreviation.toUpperCase()
      this.selectedCountryCode = selectedCountryObject.countryCode
      /* HANDLING COUNTRY CHANGE UPDATE */
      this.toogleCountrySelectModel()
    },
  },
}
</script>
